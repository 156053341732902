/* ------ FONTS ------ */
@font-face {
    font-family: 'Muller';
    src: local('Muller Bold'), local('Muller-Bold'),
        url('./../fonts/MullerBold.woff2') format('woff2'),
        url('./../fonts/MullerBold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Muller';
    src: local('Muller Medium'), local('Muller-Medium'),
        url('./../fonts/MullerMedium.woff2') format('woff2'),
        url('./../fonts/MullerMedium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Muller';
    src: local('Muller Regular'), local('Muller-Regular'),
        url('./../fonts/MullerRegular.woff2') format('woff2'),
        url('./../fonts/MullerRegular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: local('Inter Regular'), local('Inter-Regular'),
        url('./../fonts/Inter-Regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
}

/* ------ REACT CLASS ------ */
#root {
    width: 100vw;
    height: 100vh;
}

.App {
    width: 100vw;
    height: 100vh;

    overflow: hidden;
}

/* ------ LOGIN PAGE ------ */
.login__container {
    width: 100%;
    height: 100%;

    background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        from(#f80000),
        to(#920000)
    );

    background: -o-linear-gradient(top, #f80000 0%, #920000 100%);

    background: linear-gradient(180deg, #f80000 0%, #920000 100%);
}

.login__items {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.login__items img {
    margin-bottom: 50px;
}

.login__form {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.login__input {
    padding: 0 20px;
    width: 450px;
    height: 5vh;

    font-family: 'Muller', sans-serif;
    font-size: 18px;
    font-weight: 400;
    color: #7f7f7f;

    background-color: #fff;
    border-radius: 20px;

    margin-bottom: 50px;
}

.login__input:first-child {
    margin-bottom: 24px;
}

.login__btn {
    width: 250px;
    height: 5vh;

    font-family: 'Muller', sans-serif;
    font-size: 18px;
    font-weight: 400;
    color: #fff;

    background-color: #292929;
    border-radius: 20px;

    cursor: pointer;
}

.login__btn:hover {
    background-color: #3a3a3a;
}

/* ------ PLAYGROUNDS PAGE ------ */
.playgrounds__container {
    width: 100%;
    height: 100%;

    background-color: #fff;
}

.playgrounds__wrapper {
    padding-top: 20px;
    width: 100%;
    height: 100%;

    display: -webkit-box;

    display: -ms-flexbox;

    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
}

.playgrounds__side {
    padding: 0 50px;
    padding-right: 0;
}

.playgrounds__side h1 {
    font-family: 'Muller', sans-serif;
    font-size: 40px;
    font-weight: 400;
    color: #72747e;
}

.playgrounds__items {
    position: relative;

    padding: 0 70px;

    width: 100%;
    overflow-y: auto;
}

.search__items {
    margin-bottom: 40px;
}

.play__input {
    position: relative;

    width: 100%;
    height: 50px;

    border: 1px solid #aaaeb6;
    border-radius: 100px;
    padding: 0 50px;

    font-family: 'Muller', sans-serif;
    font-size: 17px;
    font-weight: 400;
    color: #aaaeb6;
}

.search__icon {
    position: absolute;
    top: 12px;
    left: 85px;
}

.playgrounds__list {
    /* display: flex;

    flex-wrap: wrap;

    justify-content: space-between;

    overflow-y: auto; */
    display: -ms-grid;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    gap: 80px;
}

.playgrounds__item {
    width: 100%;

    background-color: #e9e9e9;
    border-radius: 20px;
    cursor: pointer;
}

.myNewSwiper {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 100%;
    height: 200px;
    border-radius: 20px 20px 0 0;
}

.swiper-slide {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 20px 20px 0 0;
}

.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: var(--swiper-pagination-bottom, 8px);
    top: var(--swiper-pagination-top, auto);
    left: 0;
    width: 100%;
}

.swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    display: inline-block;
    border-radius: var(--swiper-pagination-bullet-border-radius, 50%);
    background: #000;
    opacity: 0.25;
}

.swiper-pagination-bullet-active {
    opacity: 1;
    background: #fff;
}

.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets
    .swiper-pagination-bullet {
    margin: 0 var(--swiper-pagination-bullet-horizontal-gap, 4px);
}

.swiper-pagination {
    position: absolute;
    text-align: center;
    -webkit-transition: 300ms opacity;
    -o-transition: 300ms opacity;
    transition: 300ms opacity;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    z-index: 10;
}

.playgrounds__item_about {
    padding: 16px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 10px;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: start;
}

.playgrounds__item_about h1 {
    font-family: 'Muller', sans-serif;
    font-size: 20px;
    font-weight: 700;
    color: #000000;
}

.playgrounds__item_about p {
    font-family: 'Muller', sans-serif;
    font-size: 13px;
    font-weight: 500;
    line-height: 1;
    color: #72747e;
}

.play__icon {
    height: 30px;
    text-align: left;
}

.add__wrapper {
    padding: 50px 100px;
    width: 100%;
    height: 100%;

    display: -webkit-box;

    display: -ms-flexbox;

    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.add__input {
    padding: 0 20px;

    width: 100%;
    height: 60px;

    background-color: #d9d9d9;
    border-radius: 20px;

    font-family: 'Muller', sans-serif;
    font-size: 24px;
    font-weight: 400;
    color: #000;
}

.add__textarea {
    padding: 20px 20px;
    width: 100%;

    background-color: #d9d9d9;
    border-radius: 20px;

    font-family: 'Muller', sans-serif;
    font-size: 24px;
    font-weight: 400;
    color: #000;
}

.add__item {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.add__block {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    gap: 25px;
}

.add__label {
    width: 180px;
    min-width: 180px;
    font-family: 'Inter', sans-serif;
    font-size: 24px;
    font-weight: 400;
    color: #000;
}

.add__btn_new {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-pack: distribute;
    justify-content: space-around;
}

.add__btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-pack: distribute;
    justify-content: space-around;
}

.add__btn button {
    font-family: 'Inter', sans-serif;
    font-size: 24px;
    font-weight: 400;
    color: #000;

    width: 310px;
    padding: 16px 0;
    background-color: #d9d9d9;
    border-radius: 20px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.add__button {
    font-family: 'Inter', sans-serif;
    font-size: 24px;
    font-weight: 400;
    color: #000;

    width: 310px;
    padding: 16px 0;
    background-color: #d9d9d9;
    border-radius: 20px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.add__button:hover {
    background-color: #afafaf;
}

.add__btn button:hover {
    background-color: #afafaf;
}

.add__radio {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    gap: 110px;
}

.add__radio_items {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.add__label_radio {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 18px;

    min-width: 180px;
    font-family: 'Inter', sans-serif;
    font-size: 24px;
    font-weight: 400;
    color: #000;
}

.add__label_radio span {
    width: 180px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.add__label_radio::after {
    content: '';
    display: block;
    position: relative;
    left: 0;
    top: 0;
    width: 32px;
    min-width: 32px;
    height: 32px;
    background-color: #d9d9d9;
    border-radius: 50%;
}

.add__label_radio::before {
    content: '';
    display: none;
    position: absolute;
    z-index: 100;
    left: 206px;
    top: 50%;
    margin-top: -8px;
    width: 16px;
    min-width: 16px;
    height: 16px;
    background-color: #000000;
    border-radius: 50%;
}

.add__radio_items input:checked + .add__label_radio::before {
    display: block;
}

.input__radio {
    opacity: 0;
    width: 0;
    height: 0;
}

.add__file {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.input__photo {
    opacity: 0;
    width: 0;
    height: 0;
}

.label__photo_true {
    display: block;
    width: 168px;
    height: 168px;

    background-color: #d9d9d9;
    border-radius: 20px;
}

.label__photo_false {
    display: block;
    width: 168px;
    height: 168px;

    border-radius: 20px;
}

.btn__back {
    width: 55px;
    height: 55px;
    position: absolute;
    top: 40px;
    left: 40px;
}

.image__play {
    display: block;
    width: 168px;
    height: 168px;

    border-radius: 20px;
    -o-object-fit: cover;
    object-fit: cover;
}

.add__playgrounds {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 10px;

    cursor: pointer;
}

.add__playgrounds h1 {
    font-size: 20px;
}

.add__playgrounds img {
    width: 30px;
    height: 30px;
}

.side__btn {
    margin-bottom: 10px;
}

.add__button_disabled {
    background-color: #747474;
    cursor: not-allowed;
}

.add__button_disabled:hover {
    background-color: #747474;
}
